<template>
  <div class="success-message">
    <h1 class="success-message-title">Thank you, online account opening form has been successfully submitted</h1>
    <div class="success-message-text">
      We will get in touch with you shortly.

      <br>
      <br>
      <router-link :to="{name:'Home'}" class="btn btn-black" data-test="go-main-page">Back to main page</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegistrationCompleted'
}
</script>

<style lang="less">
.success-message {
  text-align: center;
  display: table;

  max-width: 700px;
  padding: 50px;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &-title {
    font-weight: bold;
    display: block;
    margin-bottom: 20px;
    font-size: 38px;
    line-height: 1.35em;
  }
  &-text {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.35em;
  }
}
</style>
